import React, { Fragment } from "react";
import Image from "next/image";
import arrow from "../../assets/images/arrow.png";
import styles from "@/styles/perfect_gift.module.scss";
import { homeLinks } from "@/data/home";
import Link from "next/link";
const Perfect_Gift = () => {
  return (
    <Fragment>
      <section className={styles.perfect_gift}>
        <div className="container">
          <div className={styles.perfect_gift_header}>
            <h2>{homeLinks.perfectGift.title}</h2>
          </div>
          <div className="row d-flex justify-content-center">
            {homeLinks.perfectGift.images.map((v: any, i) => (
              <div className={styles.col_11} key={i}>
                <Link href={v.link} passHref>
                  <div className="w-100">
                    <div className={styles.gift_jwellery}>
                      <Image
                        src={v.image}
                        className="img-fluid"
                        alt={v.name}
                        width={204}
                        height={312}
                      />

                      <div className={styles.gift_text}>
                        <div className={styles.h3_sub}>{v.name}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="text-center pt-4">
            <a
              href={homeLinks.perfectGift.link}
              className={styles.btn_get_started}
            >
              Explore All Gifts
              {/* <Image src={arrow} className="img-fluid" alt="" /> */}
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Perfect_Gift;
